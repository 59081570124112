var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createEventHook, until } from '@vueuse/core';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { inject, onMounted, provide, ref } from 'vue';
import { $can, T } from '..';
import { useUserMutations, useUser } from '../composables/useUser';
import { useTourStore } from './../stores/tour';
import { onBeforeRouteLeave } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useRouteQuery } from '@vueuse/router';
import { useStore } from 'vuex';
export const createTour = (steps, options) => {
    const refs = new Map(steps.map(step => [step.id, ref()]));
    const stepEvent = createEventHook();
    const closeEvent = createEventHook();
    const startEvent = createEventHook();
    const tourQuery = useRouteQuery('tour');
    const store = useStore();
    const { isTourPlaying } = storeToRefs(useTourStore());
    const getElement = (id) => {
        const ref = refs.get(id);
        if (!ref.value)
            return undefined;
        if ('$el' in ref.value) {
            return ref.value.$el;
        }
        else {
            return ref.value;
        }
    };
    const registerElement = (id, element) => {
        if (!refs.has(id))
            return;
        refs.get(id).value = element;
    };
    const start = () => {
        if (isTourPlaying.value == true || !canStartTour(options.id)) {
            return;
        }
        tourQuery.value = options.id;
        // mark tour as viewed
        store.dispatch('users/loadCurrentUser')
            .then(() => completeTour(options.id));
        isTourPlaying.value = true;
        const allSteps = steps.map((step, index) => ({
            element: getElement(step.id),
            popover: Object.assign(Object.assign({}, step.popover), { onPrevClick: (element, step, options) => {
                    var _a;
                    waitForTriggerCompletion(steps[index - 1], 'prev', (_a = options.state.popover) === null || _a === void 0 ? void 0 : _a.previousButton)
                        .then(() => { var _a; return waitForStepAvailability(index - 1, 'prev', (_a = options.state.popover) === null || _a === void 0 ? void 0 : _a.previousButton); });
                }, onNextClick: (element, step, options) => {
                    var _a;
                    if (!driverObj.hasNextStep()) {
                        driverObj.destroy();
                        return;
                    }
                    waitForTriggerCompletion(steps[index + 1], 'next', (_a = options.state.popover) === null || _a === void 0 ? void 0 : _a.nextButton)
                        .then(() => { var _a; return waitForStepAvailability(index + 1, 'next', (_a = options.state.popover) === null || _a === void 0 ? void 0 : _a.nextButton); });
                } }),
        }));
        const waitForTriggerCompletion = (step, direction, driveToStepButton) => __awaiter(void 0, void 0, void 0, function* () {
            let didDisableDriveToStepButton = false;
            // Explicitly indicate inability to continue if we aren't able to do so within 500ms 
            // todo: figure out how to add a loading icon
            const disableButtonTimeout = setTimeout(() => {
                if (!driveToStepButton || driveToStepButton.classList.contains('driver-popover-btn-disabled'))
                    return;
                didDisableDriveToStepButton = true;
                driveToStepButton.classList.add('driver-popover-btn-disabled');
            }, 0);
            // promises have a higher priority then regular timeouts, button only gets disabled if something actually had to be awaited
            yield stepEvent.trigger({ id: step.id, direction: direction });
            clearTimeout(disableButtonTimeout);
            if (didDisableDriveToStepButton)
                driveToStepButton.classList.remove('driver-popover-btn-disabled');
        });
        const waitForStepAvailability = (index, direction, driveToStepButton) => __awaiter(void 0, void 0, void 0, function* () {
            if (steps[index].noElement)
                return;
            // if our target is not available after X seconds, just continue with the tour
            yield until(refs.get(steps[index].id)).toBeTruthy({ timeout: 4000, throwOnTimeout: false });
            allSteps[index].element = getElement(steps[index].id);
            if (index == 0) {
                driverObj.drive();
            }
            else {
                direction == 'next' ? driverObj.moveNext() : driverObj.movePrevious();
            }
        });
        onBeforeRouteLeave(() => {
            isTourPlaying.value = false;
        });
        const driverObj = driver({
            steps: allSteps,
            showButtons: ['next', 'close', 'previous'],
            nextBtnText: T('NEXT'),
            prevBtnText: T('PREVIOUS'),
            showProgress: true,
            progressText: '{{current}}/{{total}}',
            onDestroyed: () => {
                closeEvent.trigger();
                isTourPlaying.value = false;
                tourQuery.value = null;
            },
        });
        setTimeout(() => {
            stepEvent.trigger(steps[0].id)
                .then(() => startEvent.trigger());
        }, 200);
        waitForStepAvailability(0, 'next');
    };
    const state = { start, registerElement, onStep: stepEvent.on, onClose: closeEvent.on, onStart: startEvent.on, isTourPlaying };
    if (canStartTour(options.id) && options.permissionId
        ? $can.read[options.permissionId]
        : true && options.autoStart != false) {
        onMounted(start);
    }
    provide('tour-context', state);
    return state;
};
/**
 * Mark the given tour as completed
 * @param {string} id
 * @return {any}
 */
export const completeTour = (id) => {
    const { setMetadataValue } = useUserMutations();
    return setMetadataValue(`tour-${id}`, '1');
};
export const canStartTour = (id) => {
    const { isTourPlaying } = storeToRefs(useTourStore());
    const tourQuery = useRouteQuery('tour');
    const user = useUser();
    if (isTourPlaying.value)
        return false;
    if (tourQuery.value) {
        return tourQuery.value == id;
    }
    return !user.value.metadata['tour-' + id];
};
export const useTourContext = () => {
    return inject('tour-context', undefined);
};
